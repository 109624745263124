@import '~assets/styles/_colors.scss';

.reference {
  font-size: 13px;
  font-weight: 400;
  color: $gray;

  .reference__link {
    color: $blue;
    text-decoration: none;
  }
}
