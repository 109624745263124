@mixin absolute {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

@mixin fixed {
  position: fixed;
  left: 0;
  top: 0;
}

@mixin pseudo {
  content: '';
  position: absolute;
  display: block;
}
