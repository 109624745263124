@import '~assets/styles/_colors.scss';

.register {
  .register__input {
    background-color: $input_bg;
    margin-bottom: 47px;
  }
  .register__headline {
    margin-bottom: 20px;
  }

  .register__layout {
    .register__button {
      margin-top: 50px;
    }

    .register__clause {
      margin-top: 10px;

      .clause__text {
        font-size: 14px;
        line-height: 22px;
        color: $darkBlue;

        a {
          text-decoration: none;
          color: $darkBlue;
        }
      }
      .clause__error {
        color: $red;
        font-size: 12px;
      }
    }

    .start-layout__content {
      width: 800px;
      height: auto;
      padding: 70px 100px;
    }

    .register__text {
      color: $semiGray;
      margin-bottom: 50px;
    }

    .register__layout-content {
      width: 600px;
    }

    .register__split {
      .split__input {
        width: 50%;

        &:first-child {
          margin-right: 40px;
        }
      }
    }
  }
}
