@import 'assets/styles/colors';

.search {
  border: 1px solid $input_border;
  border-radius: 5px;

  .input-wrapper {
    border: none;

    &__start-icon {
      margin-left: 15px;
      margin-top: 5px;
    }
  }

  &__dropdown-icon {
    transform: rotate(180deg);
    path {
      fill: $purple;
    }
  }

  .filled {
    background-color: transparent;
  }

  .input {
    background: $white;
    border-radius: 5px;
    color: $darkGray;
  }
}
