@import '~assets/styles/_colors.scss';

.password-recovery {
  &--notification {
    .password-recovery__content-wrapper {
      height: 336px;
      width: 100%;
      max-width: 800px;
    }

    .password-recovery__content-container {
      width: 100%;
      max-width: 600px;
    }
  }

  &__headline {
    margin-bottom: 20px;
  }

  &__text {
    display: block;
    font-size: 18px;
    color: $semiGray;
    line-height: 26px;
    margin-bottom: 20px;
    white-space: pre-line;
  }

  &__input {
    background-color: $input_bg;
    margin-bottom: 30px;
  }

  &__button {
    margin-bottom: 50px;
  }
}
