@import '~assets/styles/_colors.scss';

.entry {
  color: $darkBlue;
  margin-bottom: 20px;

  .entry__label {
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
  }

  .entry__value {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
  }
}

.entry--with-check span::before {
  content: 'L';
  display: inline-block;
  color: black;
  transform: rotate(50deg) scaleX(-1);
  margin-right: 10px;
  font-size: 22px;
}
