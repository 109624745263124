@import 'assets/styles/colors';

.applications {
  &__details-btn {
    color: $blue;
    border: none;
    background-color: transparent;
  }

  .select {
    max-width: 223px;
  }

  .applications__filter-bar {
    width: 100%;
  }
}
