.register-success {
  .register-success__container {
    max-width: 534px;
    padding: 70px 100px;
    height: auto;
  }
  .register-success__content {
    width: 100%;
  }

  .register-success__headline {
    margin-bottom: 50px;
  }
}
