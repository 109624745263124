.activate-error {
  .activate-error__wrapper {
    height: auto;
    padding: 70px 100px;
    max-width: 534px;
  }
  .activate-error__content {
    width: 100%;
  }
}
