@import '~assets/styles/_colors.scss';

.new-application {
  padding: 0 30px 30px;
  max-width: 1920px;

  .new-application__label {
    font-size: 14px;
    margin-bottom: 5px;
    color: $darkBlue;
    font-weight: bold;
  }

  .new-application__data {
    padding: 30px 0;
    border-top: 1px solid $divider;
    border-bottom: 1px solid $divider;

    .data__leftside {
      width: 100%;
      margin-right: 40px;

      .leftside__textarea {
        width: 100%;
        height: 200px;
        padding: 10px 15px;
        font-size: 16px;
        border-radius: 5px;
        border: 1px solid $input_border;
        resize: none;

        ::placeholder {
          color: $darkGray;
        }

        &:focus {
          outline: 1px solid $semiGray;
        }
      }
    }

    .data__rightside {
      .rightside__file-upload {
        margin-bottom: 20px;
      }
    }
  }

  .new-application__actions {
    margin-top: 20px;

    .actions__button {
      margin-right: 10px;
    }
  }
}
