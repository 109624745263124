@import '~assets/styles/_colors.scss';

.client-type {
  .client-type__heading {
    font-family: 'Playfair';
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 20px;
  }

  .client-type__text {
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    color: $semiGray;
    margin-bottom: 50px;
    max-width: 312px;
  }

  .client-type__reference {
    font-size: 16px;
  }
}
