@import 'assets/styles/colors';

.text2 {
  color: $darkBlue;
  font-size: 12px;
  margin-bottom: 30px;
  display: block;
  text-align: center;
}

.text2 {
  color: $darkBlue;
  font-size: 11px;
  margin-bottom: 5px;
  display: block;
  text-align: start;
}

.welcome__headling--center {
  text-align: center;
  margin: 0 auto 50px;
}

.welcome-topbar__logo {
  width: 171px;
  height: 37px;
  margin-bottom: 30px;
}
