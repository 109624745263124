$white: #ffffff;
$blue: #00caff;
$blueHover: #00bae9;
$lightBlue: #7476ad;
$middleBlue: #8484be;
$darkBlue: #2d2f7a;
$purple: #9794bf;
$green: #98bf2c;
$yellow: #ffac07;
$pink: #cc295f;
$gray: #8692a6;
$lightGray: #f5f5f5;
$darkGray: #aeb4b9;
$semiGray: #959fb0;
$blueGray: #e7ebf0;
$blackGray: #676879;
$red: #ff0000;
$inactive: #a7a9c4;
$overlay: rgba(0, 0, 0, 0.03);
$background: #f1f3f9;

$divider: #d9dbe0;

$brand_bg: #f1f3f9;

$input_bg: #f7f7f7;
$input_border: #e4e4e5;
$checkbox_border: #dbdedf;

$button_disabledBg: #f7f8fa;
$button_border: #c8caeb;

$montserrat: 'Montserrat', 'Roboto', Arial, sans-serif;
