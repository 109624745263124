@import '~assets/styles/_colors.scss';

.login-form {
  .login-form__input {
    background-color: $input_bg;
    margin-bottom: 47px;
  }

  .login-form__clause {
    margin-bottom: 29px;
  }

  .login-form__button {
    margin-bottom: 50px;
  }
}
