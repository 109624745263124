@import '~assets/styles/_colors.scss';

.auth-notification {
  .auth-notification__headline {
    margin-bottom: 20px;
  }
  .auth-notification__text {
    font-size: 18px;
    color: $semiGray;
  }
  .auth-notification__link {
    display: block;
    margin-top: 50px;
  }
  .auth-notification__button {
    margin-top: 50px;
  }
}
