@import '~assets/styles/colors';

.change-password-modal {
  display: flex;
  justify-content: center;
  align-items: center;

  .change-password-modal__content {
    background-color: $white;
    border-radius: 5px;
    width: 670px;
    height: 680px;
    padding: 80px 150px;

    .content__headline {
      margin-bottom: 50px;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 28px;
      line-height: 34px;
      color: $darkBlue;
    }

    .content__input {
      margin-bottom: 30px;

      &:last-of-type {
        margin-bottom: 50px;
      }
    }

    .content__button {
      margin-bottom: 10px;
    }
  }
}
