@import '~assets/styles/colors';

.empty-state {
  width: 100%;
  height: 260px;
  display: flex;
  align-items: center;
  justify-content: center;

  .empty-state__container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .container__image {
      width: 104px;
      margin-bottom: 20px;
    }

    .container__text {
      font-family: $montserrat;
      font-size: 16px;
      line-height: 34px;
    }
  }
}
