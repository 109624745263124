@import '~assets/styles/_colors.scss';

.file-upload {
  min-width: 470px;

  .file-upload__icon {
    margin-right: 40px;
  }

  .file-upload__content {
    color: $inactive;
    font-weight: 400;

    .content__heading {
      font-size: 16px;
      margin-bottom: 10px;
      font-weight: 400;
    }
    .content__text {
      font-size: 12px;
    }
    .content__button {
      padding: 0 5px;
      height: auto;
      font-size: inherit;
    }
  }
}
