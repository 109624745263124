@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap');

body {
  margin: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

@font-face {
  font-family: 'Playfair';
  src: url(/assets/fonts/PlayfairDisplay-Regular.ttf) format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'Playfair';
  src: url(/assets/fonts/PlayfairDisplay-Medium.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'Playfair';
  src: url(/assets/fonts/PlayfairDisplay-SemiBold.ttf) format('truetype');
  font-weight: 600;
}
@font-face {
  font-family: 'Playfair';
  src: url(/assets/fonts/PlayfairDisplay-SemiBold.ttf) format('truetype');
  font-weight: 600;
}
@font-face {
  font-family: 'Playfair';
  src: url(/assets/fonts/PlayfairDisplay-Bold.ttf) format('truetype');
  font-weight: 700;
}
@font-face {
  font-family: 'Playfair';
  src: url(/assets/fonts/PlayfairDisplay-ExtraBold.ttf) format('truetype');
  font-weight: 800;
}
@font-face {
  font-family: 'Playfair';
  src: url(/assets/fonts/PlayfairDisplay-Black.ttf) format('truetype');
  font-weight: 900;
}

//

@font-face {
  font-family: 'Roboto';
  src: url(/assets/fonts/Roboto-Thin.ttf) format('truetype');
  font-weight: 100;
}
@font-face {
  font-family: 'Roboto';
  src: url(/assets/fonts/Roboto-Light.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Roboto';
  src: url(/assets/fonts/Roboto-Regular.ttf) format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'Roboto';
  src: url(/assets/fonts/Roboto-Medium.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'Roboto';
  src: url(/assets/fonts/Roboto-Bold.ttf) format('truetype');
  font-weight: 700;
}
@font-face {
  font-family: 'Roboto';
  src: url(/assets/fonts/Roboto-Black.ttf) format('truetype');
  font-weight: 900;
}

.fade-in-animation-enter {
  opacity: 0;
  transform: translateY(-10px);
}

.fade-in-animation-enter-active {
  transition: all 0.2s ease-in;
  transform: translateY(0);
  opacity: 1;
}

.fade-in-animation-enter-done {
  opacity: 1;
}

.fade-in-animation-exit {
  opacity: 1;
}

.fade-in-animation-exit-active {
  transition: all 0.2s ease-in;
  transform: translateY(-10px);
  opacity: 0;
}

.fade-in-animation-exit-done {
  transform: translateY(-10px);
  opacity: 0;
}

.weight400 {
  font-weight: 400;
}
.weight500 {
  font-weight: 500;
}
.weight600 {
  font-weight: 600;
}
