@import '~assets/styles/_colors.scss';

.login {
  .alert__text {
    color: red;
    font-size: 11px;
    font-weight: bold;
    margin-bottom: 30px;
    display: block;
  }
}
