@import 'assets/styles/colors';

.sidebar {
  background-color: $darkBlue;
  width: 170px;
  min-width: 170px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__logo {
    margin: 20px 0 60px 10px;
  }

  &__menu {
    margin: 0;
    padding: 0;

    button {
      margin-top: 40px;
    }
  }

  &__icon path {
    fill: $purple;
  }
}
