@import 'assets/styles/colors';

.application-details {
  margin: 10px 30px 0;
  border-top: 1px solid $input_border;
  padding-top: 20px;

  .panel,
  .application-details__headline {
    margin-bottom: 40px;
    width: 75%;
  }
}

.application-details__headline {
  .headline__label,
  .headline__data {
    color: $darkBlue;
    font-size: 21px;
    line-height: 24px;
    font-family: 'Montserrat', sans-serif;
  }
}

.application-details__status {
  max-width: 25%;
}

.application-details__files {
  .file-download {
    width: 100%;
  }
}
