@import '~assets/styles/_colors.scss';

.type-button {
  width: 100%;
  height: 110px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.13);
  margin-bottom: 30px;
  padding: 0 45px;
  font-weight: 600;
  font-size: 16px;
  transition: background-color 100ms ease-in-out;
  border-radius: 5px;

  &:hover {
    background-color: $brand_bg;
    box-shadow: none;
    outline: 0.5px solid $blue;

    .type-button__action-icon {
      opacity: 1;
      transform: translate(0);
    }
  }

  .type-button__action-icon {
    transition: opacity 100ms ease-in-out, transform 100ms ease-in-out;
    transform: translate(15px);
    opacity: 0;
  }

  .type-button__icon {
    margin-right: 30px;
  }
}
