@import '~assets/styles/_colors.scss';
@import '~assets/styles/_mixins.scss';

.checkbox {
  position: relative;
  min-width: 20px;
  max-width: 20px;
  min-height: 20px;
  max-height: 20px;
  margin-right: 10px;
  outline: 1px solid $checkbox_border;
  border-radius: 5px;

  &.checkbox--checked {
    background-color: $darkBlue;
    outline: none;

    .checkbox__mark {
      display: block;
    }
  }

  .checkbox__mark {
    @include absolute;
    display: none;
    background-image: url(~assets/images/icon-check.svg);
    background-repeat: no-repeat;
    background-position: center;
  }

  .checkbox__input {
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    opacity: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}
