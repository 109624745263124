@import 'assets/styles/colors';

.headline {
  color: $darkBlue;
  font-family: 'Montserrat';
  font-size: 30px;
  margin-bottom: 30px;
}

.login-form__headling--center {
  text-align: center;
  margin: 0 auto 50px;
}
