@import '~assets/styles/_mixins.scss';
@import '~assets/styles/_colors.scss';

.input-wrapper {
  position: relative;
  background-color: $white;
  border: 1px solid $input_border;
  border-radius: 5px;
  height: 40px;
  transition: background-color 100ms ease-in-out, border-color 100ms ease-in-out;

  &.error {
    border-color: $red;
  }
  &.width-auto {
    min-width: 400px;
    max-width: 400px;
  }
  &.width-fill {
    width: 100%;
  }
  &.width-flex {
    flex: 1;
  }

  &.focused {
    &.filled {
      background-color: $white;
    }
  }
  &.filled {
    background-color: $input_bg;
  }

  .input-wrapper__error {
    @include absolute;
    top: 45px;
    font-size: 12px;
    color: $red;
    opacity: 0;
    transition: opacity 100ms ease-in-out;

    &.visible {
      opacity: 1;
    }
  }

  .input-wrapper__icon,
  .input-wrapper__start-icon {
    display: flex;
    align-items: center;
    max-width: 20px;
    min-width: 20px;
    height: 100%;

    > img {
      width: 100%;
    }
  }

  .input-wrapper__icon {
    margin-right: 15px;
    height: 100%;
  }
  .input-wrapper__start-icon {
    margin-left: 15px;
  }

  .input {
    border: none;
    background-color: transparent;
    height: 100%;
    color: $darkBlue;
    font-size: 16px;
    padding: 3px 15px 0 15px;
    width: 100%;

    &.start-icon {
      padding-left: 10px;
    }

    &::placeholder {
      color: $darkGray;
      font-weight: 400;
      font-size: 16px;
    }

    &:focus {
      outline: transparent;
    }
  }
}
