@import 'assets/styles/colors';

.accordion-trigger:hover {
  background-color: transparent;
}

.accordion_chevron {
  margin-left: 10px;
  transition: 0.15s ease-in;
  transform: rotate(180deg);
  path {
    fill: $purple;
  }

  &--open {
    transform: rotate(0);
  }
}

.accordion__item {
  .sidebar__link {
    padding-left: 40px;
  }
  .link__label {
    margin-left: 0;
  }
  button.sidebar__link {
    margin-top: 0;
  }
}
