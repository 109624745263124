@import '~assets/styles/_colors.scss';
@import '~assets/styles/_mixins.scss';

.input-label {
  height: 24px;
  margin-bottom: 5px;

  .input-label__text {
    color: $darkBlue;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    font-family: $montserrat;

    &.required {
      position: relative;

      &::after {
        @include pseudo;
        content: '*';
        right: -7px;
        left: none;
        top: -5px;
        color: red;
      }
    }
  }

  .input-label__link {
    color: $blue;
    font-size: 14px;
    text-decoration: none;
  }
}
