@import 'assets/styles/colors';

.chip {
  box-sizing: border-box;
  background-color: rgba($purple, 0.5);
  border-radius: 5px;
  font-size: 14px;
  color: $darkBlue;
  width: fit-content;
  max-height: 20px;
  line-height: 20px;
  padding: 0 10px;

  &--with-status {
    font-size: 11px;
    text-transform: uppercase;
    max-height: 28px;
    padding: 5px 10px;
    line-height: 9px;
  }

  &--active {
    background-color: rgba($green, 0.15);
    color: $green;
  }
  &--resign {
    background-color: rgba($pink, 0.1);
    color: $pink;
  }
  &--closed {
    background-color: rgba($yellow, 0.1);
    color: $yellow;
  }
}
