@import 'assets/styles/colors';

.table {
  border-spacing: 0;
  width: 100%;
  color: $blackGray;

  &__head {
    background-color: $lightGray;
    color: black;
    text-align: left;
    font-size: 12px;
    font-weight: 500;
    font-family: 'Montserat', sans-serif;
    position: sticky;
    left: 0;
    top: 0;
    width: 100%;

    th {
      line-height: 25px;
      padding: 0;
      &:first-child {
        padding-left: 10px;
      }
    }
  }

  &__row {
    > * {
      line-height: 32px;
      display: table-cell;
    }
    td:first-child {
      padding-left: 15px;
    }
    &:nth-child(2n) {
      background-color: $lightGray;
    }
    &:hover {
      background-color: $darkBlue;
      cursor: pointer;
      color: $white;
      .chip {
        color: $white;
        &--active {
          background-color: #e8eed7;
          color: $green;
        }
        &--resignation {
          background-color: #f1e0e6;
          color: $pink;
        }
        &--in-progress {
          background-color: #f6eedc;
          color: $yellow;
        }
      }
    }

    td {
      max-height: 32px;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 400px;
      white-space: nowrap;
      padding-right: 30px;
    }
  }
}
