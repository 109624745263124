@import 'assets/styles/colors';

.date-picker {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 15px;
  height: 40px;
  background: $white;
  border: 1px solid $input_border;
  border-radius: 5px;
  color: $darkGray;
  margin: 0 20px;
  min-width: 250px;

  &__input {
    background: $white;
    font-size: 16px;
    letter-spacing: 0.03em;
    color: $darkGray;
    border: none;
    outline: none;
    margin: 0 10px;
  }

  &__chevron {
    width: 20px;
    transform: rotate(180deg);
    margin-left: auto;
    path {
      fill: $purple;
    }
  }
}
