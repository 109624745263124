@import '~assets/styles/_colors.scss';

.pagination {
  height: 50px;
  width: 100%;

  &__limit {
    color: $middleBlue;
    font-size: 14px;
    font-weight: 400;
    margin-right: 50px;

    > * {
      margin-right: 4px;
    }

    .limit__total {
      font-weight: 500;
      font-size: 12px;
    }
  }

  &__pages {
    margin-right: 50px;
  }

  &__page-count {
    font-weight: 500;
    font-size: 12px;
    color: $middleBlue;
    margin: 0 10px;
  }

  &__page-nav {
    border: 1px solid $input_border !important;
    border-radius: 5px !important;
    margin-right: 5px !important;
  }

  &__go-to-page {
    font-size: 12px;
    font-weight: 600;
    color: $middleBlue;
    margin-right: 50px;

    input[type='number'] {
      border: 1px solid $input_border !important;
      border-radius: 5px !important;
      color: #aeb4b9;
      margin-left: 10px;
      outline: none;
      padding: 6px 0px;
      text-align: center;
      width: 34px;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
}
