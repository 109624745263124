@import '~assets/styles/_colors.scss';

.reset-password {
  .reset-password__headline {
    margin-bottom: 50px;
  }

  .reset-password__input {
    background-color: $input_bg;
    margin-bottom: 47px;
  }

  .reset-password__button {
    &:first-child {
      margin-bottom: 10px;
    }
  }
}
