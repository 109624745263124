@import 'assets/styles/colors';

.panel {
  line-height: 24px;
  color: $darkBlue;
  font-family: 'Roboto';

  &__label {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 5px;
  }

  &__content {
    font-weight: 300;
    font-size: 16px;
  }
}
