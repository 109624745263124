@import '~assets/styles/_colors.scss';

.file-list {
  max-width: 470px;

  .file-list__item {
    height: 44px;
    background-color: $input_bg;
    padding: 0 10px;
    border-radius: 5px;
    margin-bottom: 5px;

    .item__file-name {
      width: 70%;
      color: $darkBlue;
      font-size: 14px;
      letter-spacing: 0.03em;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .item__file-size {
      min-width: 70px;
      font-size: 14px;
      color: $middleBlue;
    }
  }
}
